import React, { useState } from 'react';

import Footer from './components/commons/Footer';
import Header from './components/commons/Header';
import Menu from './components/commons/Menu';
import AboutUs from './components/screens/AboutUs';
import Home from './components/screens/Home';
import Services from './components/screens/Services';

import Scrollspy from 'react-scrollspy';

import './App.scss'

function App() {
  const [section, setSection] = useState<string>('home')

  return (
    <div className='main-layout' >
      <Scrollspy 
        items={['home', 'about-us', 'services', 'footer']} 
        currentClassName='isCurrent'
        onUpdate={(data: any) => setSection(`${data.id}-header`)}
      >
        <Header section={section}/>
        <Menu section={section}/>
      </Scrollspy>
      <section id='home'>
        <Home />
      </section>
      <section id='about-us'>
        <AboutUs />
      </section>
      <section id='services'>
        <Services />
      </section>
      <section id='footer'>
        <Footer />
      </section>
    </div>
  );
}

export default App;
