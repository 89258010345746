import React from 'react'

import './styles.scss'

export default function AboutUs() {
    return (
        <div className='about-us'>
            <div className='quote'>
                Nuestro pensamiento esta enfocado a prestar, asesorías, consultorías de prevención y conciliaciones.
            </div>
            <div className='quote'>
                Somos un equipo de abogadas especializadas, que ejerce el derecho con responsabilidad, conocimiento 
                y pasión, dispuestas a darlo todo por la causa.
            </div>
        </div>
    )
}
