import React from 'react'

import civil from '../../assets/iconos/civil.png'
import laboral from '../../assets/iconos/laboral.png'
import familia from '../../assets/iconos/familia.png'
import constitucional from '../../assets/iconos/constitucional.png'
import comercial from '../../assets/iconos/comercial.png'

import Carousel from 'components/commons/Carousel/Carousel'
import Slide from 'components/commons/Carousel/Slide'
import { ICarouselItem } from 'components/commons/Carousel/Carousel'

import './styles.scss'

export default function Services() {

    const services: Array<ICarouselItem> = [
        {
            image: civil,
            title: 'Civil',
            description: 'Civil',
        },
        {
            image: laboral,
            title: 'Laboral',
            description: 'Laboral',
        },
        {
            image: familia,
            title: 'Familia',
            description: 'Familia',
        },
        {
            image: constitucional,
            title: 'Constitucional',
            description: 'Constitucional',
        },
        {
            image: comercial,
            title: 'Comercial',
            description: 'Comercial',
        },
    ]

    const newService: any[] = [
        { description: `Recuperamos su inmueble cuando el arrendatario no paga ni quiere desocupar.` },
        { description: `Cobramos su letra de cambio, divorcio y liquidación Conyugal.` },
        { description: `Liquidación patrimonial.` },
        { description: `Elaboración de peticiones, tutelas, recursos de apelación y de reposición contra empresas prestadoras de servicios públicos.` }
    ]

    return (
        <div className='services'>
            <div className='description'>Asesorías y soluciones en las siguientes ramas del derecho:</div>
            <div className='service-list'>
                <Carousel 
                    items={services}
                    autoplay={true}
                    showBackArrow={true}
                    showNextArrow={true}
                />
                {/* <Carousel>
                    {services.map((s: any) => (
                        <Slide image={s.image} text={s.title}/>
                    ))}
                </Carousel> */}
                {/* <ul>
                    {newService.map((s: any, i: any) => (
                        <li key={`service-${i}`}>{s.description}</li>
                    ))}
                </ul> */}
                <div className='content-list-services'>
                    {newService.map((s: any, i: any) => (
                        <div className='list-item' key={`newservice-${i}`}>
                            {s.description}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
