import React from 'react'
import logo from '../../assets/logo/logo.png'
import { Animated } from 'react-animated-css'

import '../screens/styles.scss'

interface IHeaderProps {
    section: any | undefined
}

const Header: React.FC<IHeaderProps> = (props) => {
    const size = 15

    return (
        <div className={`header`}>
            <Animated 
                animationIn='fadeIn' 
                animationOut='fadeOut' 
                isVisible={true} 
                animationInDuration={2500}
                animationOutDuration={2000}
            >
                <div className={`logo ${props.section}`} style={{ width: `${size}rem`, height: `${size}rem` }}>
                        <img src={logo} alt='L&M Asociadas' title='L&M Asociadas' width='80%' height='80%'/>
                </div>
            </Animated>
        </div>
    )
}

export default Header