import React, { useRef } from 'react'
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'
import styled, { css } from 'styled-components'

import './carouselStyles.scss'

export interface ICarouselProps {
    items: Array<ICarouselItem>
    autoplay: boolean
    showBackArrow: boolean
    showNextArrow: boolean
}

export interface ICarouselItem {
    image: any
    title?: string
    description?: string
}

interface ICarouselSlide {
    active?: boolean
}

// interface ICarouselProps {
//     currentSlide: number
// }

const SCarouselComponent = styled.div`
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
`

const SCarouselWrapper = styled.div`
    width: 90%;
    min-height: 100px;
    display: flex;
`

const SCarouselSlide = styled.div<ICarouselSlide>`
    flex: 0 0 auto;
    opacity: ${props => (props.active ? 1 : 0)};
    transition: all 0.5s ease;
    width: 100%
`

// const SCarouselSlides = styled.div<ICarouselProps>`
//     display: flex;
//     ${props => props.currentSlide && css`transform: translateX(-${props.currentSlide * 100}%);`};
//     transition: all 0.5s ease;
// `

const SCarouselButton = styled.div`
    color: #FFF;
    background-color: transparent;
    border: 0;
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 2rem;

    :hover {
        background-color: rgba($color: #000000, $alpha: .5);
    }
`

interface IProps {
    children: JSX.Element[]
}

const Carousel: React.FC<ICarouselProps> = (props) => {
// const Carousel: React.FC<IProps> = ({ children }) => {
    // const [currentSlide, setCurrentSlide] = React.useState<any>(0)
    
    // const activeSlide = children.map((slide: any, index: number) => (
    //     <SCarouselSlide active={currentSlide === index} key={index}>
    //         {slide}
    //     </SCarouselSlide>
    // ))

    return (
        // <div className='carousel-component'>
        //     {/* Back button */}
        //     <SCarouselButton as='button' onClick={() => {
        //         setCurrentSlide((currentSlide - 1 + activeSlide.length) % activeSlide.length)
        //     }}>
        //         <MdArrowBackIosNew />
        //     </SCarouselButton>
        //     {/* Slides content */}
        //     <SCarouselWrapper>
        //         <SCarouselSlides currentSlide={currentSlide}>
        //             {activeSlide}
        //         </SCarouselSlides>
        //     </SCarouselWrapper>
        //     {/* Next button */}
        //     <SCarouselButton as='button' onClick={() => {
        //         setCurrentSlide((currentSlide + 1) % activeSlide.length)
        //     }}>
        //         <MdArrowForwardIos />
        //     </SCarouselButton>
        // </div>
        <div className='carousel-component'>
            {/* <div className='carousel-controls'>
                {props.showBackArrow ? (
                    <div className='back-arrow' onClick={() => executeScroll()}>
                        <MdArrowBackIosNew />
                    </div>
                ) : null}
            </div> */}
            <div className='carousel-view-items'>
                {props?.items?.map((item: any, i: any) => (
                    <div className='carousel-card-item' key={`item-${i}`} >
                        <img
                            src={item.image} 
                            alt={item.title} 
                            title={item.title}
                            width='100px'
                            height='100px'
                        />
                        <span>{item.title}</span>
                    </div>
                ))}
            </div>
            {/* <div className='carousel-controls'>
                {props.showNextArrow ? (
                    <div className='next-arrow' onClick={() => executeScroll()}>
                        <MdArrowForwardIos />
                    </div>
                ) : null}
            </div> */}
        </div>
    )
}

export default Carousel
