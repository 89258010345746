import React from 'react'

import '../screens/styles.scss'

interface IMenuProps {
    section: any | undefined
}

const Menu: React.FC<IMenuProps> = (props) => {
    const options = [
        { id: 'home-header', value: 'Inicio', section: 'home'},
        { id: 'about-us-header', value: 'Quienes Somos', section: 'about-us'},
        { id: 'services-header', value: 'Servicios', section: 'services'},
    ]
    return (
        <div className='menu'>
            <div className='content-options'>
                {options.map((o: any, i: any) => (
                    <div className={`option`} key={`menu-${i}`}>
                        <a href={`#${o.section}`}>
                            {o.value}
                        </a>
                        {props.section === o.id ? (
                            <div className='dot'></div>
                        ) : null}
                    </div>
                ))}
            </div>
            <div className='vertical-bar'></div>
        </div>
    )
}

export default Menu