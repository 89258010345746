import React from 'react'
import { Animated } from 'react-animated-css'

import './styles.scss'

export default function Home() {
    return (
        <div className='home'>
            <Animated
                animationIn='fadeIn' 
                animationOut='fadeOut' 
                isVisible={true} 
                animationInDuration={2500}
                animationOutDuration={2000}
            >
                <div className='quote'>
                    " Nuestros clientes son la principal motivación para dar la batalla jurídica."
                </div>
            </Animated>
        </div>
    )
}
