import React from 'react'

import linkedin from '../../assets/iconos/linkedin.png'
import instagram from '../../assets/iconos/instagram.png'
import twitter from '../../assets/iconos/twitter.png'
import phone from '../../assets/iconos/phone.png'
import mail from '../../assets/iconos/mail.png'
import whatsapp from '../../assets/iconos/whatsapp.png'

import '../screens/styles.scss'

export default function Footer() {
    const size = { width: '50px', height: '50px' }

    return (
        <div className='footer'>
            <div className='title-contact'>Información de contacto</div>
            <div className='social-networks'>
                <div className='row' style={{ width: '100%' }}>
                    <div className='col-sm-4' style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignContent: 'flex-start',
                    }}>
                        <div className='social-network'>
                            <img 
                                className='icon' 
                                src={linkedin} 
                                width={size.width} 
                                height={size.height} 
                                alt='linkedin' 
                                title='linkedin'
                            />
                            <a className='text' href='https://www.linkedin.com/company/l-m-asociadas/about/' target='_blank'>L&M Asociadas</a>
                        </div>
                        <div className='social-network'>
                            <img 
                                className='icon' 
                                src={instagram} 
                                width={size.width} 
                                height={size.height} 
                                alt='instagram' 
                                title='instagram'
                            />
                            <a className='text' href='https://www.instagram.com/lymasociadas/' target='_blank'>lymasociadas</a>
                        </div>
                    </div>
                    <div className='col-sm-4' style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignContent: 'flex-start',
                    }}>
                        <div className='social-network'>
                            <img 
                                className='icon' 
                                src={twitter} 
                                width={size.width} 
                                height={size.height} 
                                alt='twitter' 
                                title='twitter'
                            />
                            <a className='text' href='https://twitter.com/AsociadasL' target='_blank'>@AsociadasL</a>
                        </div>
                        <div className='social-network'>
                            <img 
                                className='icon' 
                                src={phone} 
                                width={size.width} 
                                height={size.height} 
                                alt='phone' 
                                title='phone'
                            />
                            <div className='text' >324 369 0318</div>
                        </div>
                    </div>
                    <div className='col-sm-4' style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignContent: 'flex-start',
                    }}>
                        <div className='social-network'>
                            <img 
                                className='icon' 
                                src={mail} 
                                width={size.width} 
                                height={size.height} 
                                alt='mail' 
                                title='mail'
                            />
                            <div className='text' >asociadaslym@gmail.com</div>
                        </div>
                        <div className='social-network'>
                            <img 
                                className='icon' 
                                src={whatsapp} 
                                width={size.width} 
                                height={size.height} 
                                alt='whatsapp' 
                                title='whatsapp'
                            />
                            <a className='text' href='https://api.whatsapp.com/send?phone=3243690318&text=Esto%20es%20una%20prueba.' target='_blank'>Contactanos en whatsapp</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
